import React, { useState, useEffect } from "react"
import MenuItem from "./MenuItem"
import { transformMenuItems } from "../../utils/TransformMenuItems"

export function Menu({ menuItems, wordPressUrl }) {
  const [transformedData, setTransformedData] = useState([])
  useEffect(() => {
    if (menuItems && menuItems.length > 0) {
      const transformedData = transformMenuItems(menuItems)
      setTransformedData(transformedData)
    } else {
      setTransformedData([])
    }
  }, [menuItems])

  return (
    <>
      {transformedData && transformedData.map(menuItem => (
          <MenuItem
            key={menuItem.id}
            menuItem={menuItem}
            wordPressUrl={wordPressUrl}
          />
        ))}
    </>
  )
}
