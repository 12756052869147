import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ObtenerUri from "../../utils/ObtenerUri"
import "./RelacionadosEntrada.scss"

const RelacionadosEntrada = ({ categorias }) => {
  // Obtener los artículos relacionados basados en las categorías
  const data = useStaticQuery(graphql`
    query {
      pionero {
        posts(first: 10) {
          nodes {
            title
            uri
            date
            categories {
              nodes {
                name
              }
            }
            entrada {
              imagenPortada {
                altText
                title
                srcSet
                sourceUrl
                sourceUrlSharp {
                  childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // Filtrar los artículos relacionados por las categorías
  const relatedPosts = []
  const usedPosts = new Set() // Para evitar duplicados

  // Si hay más de una categoría, usar las dos primeras
  const categoriasUsadas = categorias.slice(0, 2)

  // Si solo hay una categoría, duplicarla para obtener dos artículos
  if (categoriasUsadas.length === 1) {
    categoriasUsadas.push(categoriasUsadas[0])
  }

  categoriasUsadas.forEach((categoria) => {
    const post = data.pionero.posts.nodes.find(
      (node) =>
        !usedPosts.has(node.uri) && // Evitar duplicados
        node.categories.nodes.some((cat) => cat.name === categoria.name)
    )
    if (post) {
      relatedPosts.push(post)
      usedPosts.add(post.uri) // Marcar el artículo como usado
    }
  })

  return (
    <section className="relacionados-entrada">
      <div className="relacionados-entrada-titulo">
        <h4 className="relacionados-entrada-linea">Artículos Relacionados</h4>
        <Link to="/blog/" className="relacionados-entrada-volver">
          Ver todos los artículos
        </Link>
      </div>
      <div className="relacionados-entrada-grid">
        {relatedPosts.map((post, index) => (
          <div key={index} className="relacionados-entrada-item">
            <div className="entrada-item-data">
              <div className="entrada-item-categorias">
                {post.categories.nodes.map((cat, catIndex) => (
                  <span key={catIndex} className="entrada-item-categoria">
                    {cat.name}
                  </span>
                ))}
              </div>
              <h3 className="entrada-item-titulo">{post.title}</h3>
              <Link to={ObtenerUri(post.uri)} className="entrada-item-boton">
                Leer artículo
              </Link>
            </div>
            {post.entrada.imagenPortada && (
              <GatsbyImage
                image={post.entrada.imagenPortada.sourceUrlSharp.childImageSharp.gatsbyImageData}
                alt={post.entrada.imagenPortada.altText || post.title}
                className="entrada-item-imagen"
              />
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default RelacionadosEntrada