import React from "react";
import Layout from "../../components/Layout/Layout";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import "./Gracias_blog.scss";

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug
        content
        seo {
          title
          metaDesc
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
        }
        gracias_blog {
          descripcionGracias
          tituloGracias
          imagenGracias {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
        }
      }
    }
  }
`;

export default function GraciasBlog({ data }) {
  const page = data.pionero.page;

  setTimeout(() => {
    navigate("/")
  }, 4000)

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page?.title} </h1>
      <div className="gracias-blog">
        {/* Renderizar la imagen de fondo */}
        {page.gracias_blog?.imagenGracias?.sourceUrlSharp?.childImageSharp?.gatsbyImageData && (
          <GatsbyImage
            image={page.gracias_blog.imagenGracias.sourceUrlSharp.childImageSharp.gatsbyImageData}
            alt={page.gracias_blog?.imagenGracias?.altText || "Imagen de fondo"}
            className="gracias-blog-background"
          />
        )}
        <div className="gracias-blog-content">
          <h2
            className="gracias-blog-title"
            dangerouslySetInnerHTML={{
              __html: page.gracias_blog?.tituloGracias || page.title,
            }}
          />
          {page.gracias_blog?.descripcionGracias && (
            <p
              className="gracias-blog-description"
              dangerouslySetInnerHTML={{
                __html: page.gracias_blog.descripcionGracias,
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}