import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FormSuscribete from "../../components/Formularios/FormSuscribete"
import "./SuscribeteBlog.scss"

const SuscribeteBlog = () => {
  // Consulta GraphQL para obtener los datos de suscripción
  const data = useStaticQuery(graphql`
    query {
      pionero {
        page(id: "5024", idType: DATABASE_ID) {
          blog {
            suscripcion {
              descripcion
              titulo
            }
          }
        }
      }
    }
  `)

  const suscripcion = data.pionero?.page?.blog?.suscripcion

  return (
    <div className="suscribete-blog mx-auto">
      <div className="suscribete-blog-textos">
        <h3
          className="suscribete-blog-titulo"
          dangerouslySetInnerHTML={{ __html: suscripcion?.titulo }}
        />
        <p
          className="suscribete-blog-descripcion"
          dangerouslySetInnerHTML={{ __html: suscripcion?.descripcion }}
        />
      </div>
      <FormSuscribete />
    </div>
  )
}

export default SuscribeteBlog