import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import GenerarAlt from "../../utils/GenerarAlt";
import "./ContenidoEntrada.scss";
import FormEntradas from "../../components/Formularios/FormEntradas";
import { SVGLogoPionero, SVGLinkedIn, SVGFacebook, SVGInstagram } from "./SvgsEntrada";

const ContenidoEntrada = ({
  fecha,
  titulo,
  categorias,
  imagenPortada,
  contenido,
  nombreAutor,
  imagenAutor,
  imagenFormulario,
  proyectos,
}) => {
  // Estado para almacenar la URL actual
  const [currentUrl, setCurrentUrl] = useState("");

  // Actualizar la URL solo en el cliente
  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentUrl(window.location.href);
    }
  }, []);

  // Formatear la fecha a "día mes año"
  const formatearFecha = (fecha) => {
    const opciones = { day: "2-digit", month: "long", year: "numeric" };
    return new Date(fecha).toLocaleDateString("es-ES", opciones);
  };

  return (
    <section className="contenido-entrada">
      {fecha && <span className="contenido-entrada-fecha">{formatearFecha(fecha)}</span>}

      {titulo && (
        <h3
          className="contenido-entrada-titulo"
          dangerouslySetInnerHTML={{ __html: titulo }}
        />
      )}

      {categorias && categorias.length > 0 && (
        <div className="contenido-entrada-categorias">
          {categorias.map((categoria, index) => (
            <span
              key={index}
              className="contenido-entrada-categoria"
              style={{
                backgroundColor: categoria?.entrada_categoria?.colorCategoria || "#000",
              }}
            >
              {categoria.name}
            </span>
          ))}
        </div>
      )}

      {imagenPortada && (
        <div className="contenido-entrada-imagen">
          <GatsbyImage
            image={imagenPortada?.sourceUrlSharp?.childImageSharp?.gatsbyImageData}
            alt={GenerarAlt(imagenPortada)}
          />
        </div>
      )}

      {contenido && (
        <div>
          <div
            className="contenido-entrada-contenido"
            dangerouslySetInnerHTML={{ __html: contenido }}
          />

          <div className="contenido-entrada-autor">
            <div className="contenido-entrada-autor__data">
              {imagenAutor ? (
                <GatsbyImage
                  image={imagenAutor?.sourceUrlSharp?.childImageSharp?.gatsbyImageData}
                  className="contenido-entrada-autor__imagen"
                  alt={GenerarAlt(imagenAutor)}
                />
              ) : (
                <SVGLogoPionero className="contenido-entrada-autor__svg" />
              )}
              <h4
                dangerouslySetInnerHTML={{
                  __html: nombreAutor || "Pionero Inmobiliaria",
                }}
              />
            </div>

            {/* Links para compartir */}
            <div className="contenido-entrada-autor__compartir">
              <span>Compartir:</span>
              {currentUrl && (
                <>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                      currentUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contenido-entrada-autor__icon"
                  >
                    <SVGLinkedIn />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      currentUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contenido-entrada-autor__icon"
                  >
                    <SVGFacebook />
                  </a>
                  <a
                    href={`https://www.instagram.com/?url=${encodeURIComponent(
                      currentUrl
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contenido-entrada-autor__icon"
                  >
                    <SVGInstagram />
                  </a>
                </>
              )}
            </div>
          </div>

          <div className="contenido-entrada-formulario">
            {imagenFormulario && (
              <div className="contenido-entrada-formulario__imagen">
                <GatsbyImage
                  image={imagenFormulario?.sourceUrlSharp?.childImageSharp?.gatsbyImageData}
                  alt={GenerarAlt(imagenFormulario)}
                />
              </div>
            )}
            <FormEntradas proyectos={proyectos} />
          </div>
        </div>
      )}
    </section>
  );
};

export default ContenidoEntrada;