import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import "./Entrada.scss";
import ContenidoEntrada from "../../components/Entrada/ContenidoEntrada"
import RelacionadosEntrada from "../../components/Entrada/RelacionadosEntrada"

export const query = graphql`
  query($id: ID!) {
    pionero {
      post(id: $id, idType: DATABASE_ID) {
        title
        content
        uri
        slug
        date
        entrada {
          contenido
          imagenPortada {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          nombreAutor
          imagenAutor {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          imagenFormulario {
            altText
            title
            srcSet
            sourceUrl
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        categories {
          nodes {
            name
            entrada_categoria {
              colorCategoria
            }
          }
        }
      }
      proyectos (first: 100) {
        nodes {
          title
          proyecto {
            correoCotiza
            idProyectoCotiza
            urlGraciasCotiza {
              ... on Pionero_Proyecto {
                uri
              }
            }
            urlGraciasBlog
          }
        }
      }
    }
  }
`

export default function Entrada({ data }) {
  const page = data.pionero.post

  useEffect(() => {
    const header = document.querySelector("header")
    if (header) {
      header.style.setProperty("background-color", "#2D2D2D")
    }
    document.documentElement.style.setProperty("--color-texto", "#e8d5cc")
  }, [])

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page?.title} </h1>
      <section className="contenido-entrada-box">
        <div className="contenido-entrada-volver">
          <Link to="/blog/" className="contenido-entrada-volver__link">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M10.865 12.4997L15.832 17.4668L14.4132 18.8857L8.02721 12.4997L14.4132 6.11377L15.832 7.53264L10.865 12.4997Z" fill="#2D2D2D" />
            </svg>
            Volver
          </Link>
        </div>
        <ContenidoEntrada
          fecha={page.date}
          titulo={page.title}
          categorias={page.categories.nodes}
          imagenPortada={page.entrada.imagenPortada}
          contenido={page.entrada.contenido}
          nombreAutor={page.entrada.nombreAutor}
          imagenAutor={page.entrada.imagenAutor}
          imagenFormulario={page.entrada.imagenFormulario}
          proyectos={data.pionero.proyectos.nodes}
        />

      </section>
      <RelacionadosEntrada categorias={page.categories.nodes} />
    </Layout >
  )
}