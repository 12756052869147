import React, { useState } from "react"
import { Link } from "gatsby"

const FormSuscribete = () => {
  const [email, setEmail] = useState("")
  const [msgEnvio, setMsgEnvio] = useState("")
  const [envio, setEnvio] = useState(false)
  const [aceptar, setAceptar] = useState(false) // Estado para el checkbox

  const handleSubmit = async event => {
    event.preventDefault()

    if (!aceptar) {
      setMsgEnvio("Debes aceptar los términos y condiciones.")
      return
    }

    if (email === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setMsgEnvio("Por favor, ingresa un correo electrónico válido.")
      return
    }

    const formdata = new FormData()
    formdata.append("email", email)
    formdata.append("_wpcf7_unit_tag", "wpcf7-f1136-o1")

    try {
      const response = await fetch(
        `${process.env.GATSBY_PAGINA_COMPILADOR}/wp-json/contact-form-7/v1/contact-forms/5096/feedback`,
        {
          method: "POST",
          body: formdata,
        }
      )
      const result = await response.json()

      if (result.status === "mail_sent") {
        setMsgEnvio("Gracias por suscribirte. ¡Revisa tu correo!")
        setEnvio(true)
        setEmail("") // Limpiar el campo de email
        setAceptar(false) // Resetear el checkbox
      } else {
        setMsgEnvio("Hubo un error al enviar tu suscripción. Inténtalo nuevamente.")
        setEnvio(false)
      }
    } catch (error) {
      setMsgEnvio("Error al conectar con el servidor. Inténtalo más tarde.")
      setEnvio(false)
    }
  }

  return (
    <form className="form-suscribete" onSubmit={handleSubmit} encType="multipart/form-data" noValidate>
      <input
        type="email"
        name="email"
        className="form-suscribete-input"
        placeholder="Ingresa tu correo"
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <label className="form-suscribete-checkbox">
        <input
          type="checkbox"
          checked={aceptar}
          onChange={e => setAceptar(e.target.checked)}
        />
        <span>
          He leído y acepto todos los{" "}
          <Link
            to="/terminos-y-condiciones/"
            className="color-blanco weight-bold"
            target="_blank"
            rel="noreferrer"
          >
            términos y condiciones
          </Link>
          , y la{" "}
          <Link
            to="/politica-privacidad/"
            className="color-blanco weight-bold"
            target="_blank"
            rel="noreferrer"
          >
            política de privacidad
          </Link>{" "}
          del Grupo Pionero Inmobiliaria
        </span>
      </label>
      <button
        type="submit"
        className="form-suscribete-boton"
        disabled={envio}
      >
        Suscribirme
      </button>
      {msgEnvio && <p className="form-suscribete-mensaje">{msgEnvio}</p>}
    </form>
  )
}

export default FormSuscribete