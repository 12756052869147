import React from "react";

// Logo de Pionero
export const SVGLogoPionero = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.53 24.57" {...props}>
    <defs>
      <style>{".cls-1{fill:#2e2e2e;}"}</style>
    </defs>
    <title>{"Recurso 5"}</title>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <polygon
          className="cls-1"
          points="26.52 4.73 35.36 4.73 35.36 19.84 26.52 19.84 26.52 24.41 50.75 24.41 50.75 19.84 41.32 19.84 41.32 4.73 50.75 4.73 50.75 0.17 26.52 0.17 26.52 4.73"
        />
        <path
          className="cls-1"
          d="M18.13,7.79a2.63,2.63,0,0,1-1.07,2.41,6.32,6.32,0,0,1-3.25.67H5.93V4.71h7.88A9,9,0,0,1,16.18,5a2.7,2.7,0,0,1,1.44.93,3,3,0,0,1,.51,1.86Zm3.41-5.92A13.82,13.82,0,0,0,14.08.17H0V24.41H5.93V15.3H14a13.71,13.71,0,0,0,7.49-1.74q2.69-1.73,2.69-5.87v0q0-4.09-2.67-5.8"
        />
        <path
          className="cls-1"
          d="M73.6,17.95a7.73,7.73,0,0,0,2-5.61v-.1a7.72,7.72,0,0,0-2-5.61q-2-2.05-6.47-2.05T60.65,6.63a7.73,7.73,0,0,0-2,5.61v.1a7.74,7.74,0,0,0,2,5.61q2,2.05,6.48,2.05t6.47-2.05M59.21,23.11a10.56,10.56,0,0,1-4.89-4.21,12.52,12.52,0,0,1-1.65-6.57v-.1a12.52,12.52,0,0,1,1.65-6.57,10.58,10.58,0,0,1,4.89-4.2A19.15,19.15,0,0,1,67.13,0,19.06,19.06,0,0,1,75,1.47a10.59,10.59,0,0,1,4.88,4.2,12.52,12.52,0,0,1,1.65,6.57v.1a12.52,12.52,0,0,1-1.65,6.57A10.56,10.56,0,0,1,75,23.11a19.08,19.08,0,0,1-7.9,1.47,19.17,19.17,0,0,1-7.91-1.47"
        />
        <path
          className="cls-1"
          d="M247.58,17.95a7.74,7.74,0,0,0,2-5.61v-.1a7.73,7.73,0,0,0-2-5.61q-2-2.05-6.48-2.05t-6.48,2.05a7.72,7.72,0,0,0-2,5.61v.1a7.73,7.73,0,0,0,2,5.61q2,2.05,6.48,2.05t6.48-2.05m-14.39,5.16a10.55,10.55,0,0,1-4.89-4.21,12.55,12.55,0,0,1-1.64-6.57v-.1a12.55,12.55,0,0,1,1.64-6.57,10.58,10.58,0,0,1,4.89-4.2,22.08,22.08,0,0,1,15.82,0,10.58,10.58,0,0,1,4.88,4.2,12.54,12.54,0,0,1,1.64,6.57v.1a12.54,12.54,0,0,1-1.64,6.57A10.56,10.56,0,0,1,249,23.11a19.09,19.09,0,0,1-7.9,1.47,19.16,19.16,0,0,1-7.91-1.47"
        />
        <polygon
          className="cls-1"
          points="160.59 0.17 160.59 18.87 91.05 0.17 84.97 0.17 84.97 24.41 90.88 24.41 90.88 5.8 160.5 24.41 166.5 24.41 166.5 0.17 160.59 0.17"
        />
        <polygon
          className="cls-1"
          points="171.12 24.39 195.35 24.39 195.35 19.72 177 19.72 177 14.42 193.9 14.42 193.9 9.92 177 9.92 177 4.83 195.35 4.83 195.35 0.16 171.12 0.16 171.12 24.39"
        />
        <path
          className="cls-1"
          d="M216.68,9.51a5.86,5.86,0,0,1-2.92.58h-8.07V4.74h7.74a10.65,10.65,0,0,1,2.33.21,2.51,2.51,0,0,1,1.39.78,2.52,2.52,0,0,1,.49,1.67,2.23,2.23,0,0,1-1,2.1M220,12a5.24,5.24,0,0,0,2.69-1.92,5.84,5.84,0,0,0,.95-3.47q0-3.59-2.53-5A14.54,14.54,0,0,0,214.06.14H199.72V24.38h6V14.51h8.24a4.71,4.71,0,0,1,3.09.85,3.36,3.36,0,0,1,1,2.7v6.33h5.95V17.7a6.25,6.25,0,0,0-1-3.7A5.24,5.24,0,0,0,220,12"
        />
      </g>
    </g>
  </svg>
);

// Ícono de LinkedIn
export const SVGLinkedIn = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M30.2222 0C31.2241 0 32.185 0.398015 32.8935 1.10649C33.602 1.81496 34 2.77585 34 3.77778V30.2222C34 31.2241 33.602 32.185 32.8935 32.8935C32.185 33.602 31.2241 34 30.2222 34H3.77778C2.77585 34 1.81496 33.602 1.10649 32.8935C0.398015 32.185 0 31.2241 0 30.2222V3.77778C0 2.77585 0.398015 1.81496 1.10649 1.10649C1.81496 0.398015 2.77585 0 3.77778 0H30.2222ZM29.2778 29.2778V19.2667C29.2778 17.6335 28.629 16.0673 27.4742 14.9125C26.3194 13.7577 24.7531 13.1089 23.12 13.1089C21.5144 13.1089 19.6444 14.0911 18.7378 15.5644V13.4678H13.4678V29.2778H18.7378V19.9656C18.7378 18.5111 19.9089 17.3211 21.3633 17.3211C22.0647 17.3211 22.7373 17.5997 23.2332 18.0956C23.7292 18.5916 24.0078 19.2642 24.0078 19.9656V29.2778H29.2778ZM7.32889 10.5022C8.17051 10.5022 8.97766 10.1679 9.57277 9.57277C10.1679 8.97766 10.5022 8.17051 10.5022 7.32889C10.5022 5.57222 9.08556 4.13667 7.32889 4.13667C6.48226 4.13667 5.6703 4.47299 5.07165 5.07165C4.47299 5.6703 4.13667 6.48226 4.13667 7.32889C4.13667 9.08556 5.57222 10.5022 7.32889 10.5022ZM9.95444 29.2778V13.4678H4.72222V29.2778H9.95444Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

// Ícono de Facebook
export const SVGFacebook = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M32.13 0H1.87C1.37405 0 0.898404 0.197017 0.547711 0.547711C0.197017 0.898404 0 1.37405 0 1.87V32.13C0 32.626 0.197017 33.1016 0.547711 33.4523C0.898404 33.803 1.37405 34 1.87 34H18.156V20.825H13.736V15.725H18.156V11.9C18.0644 11.002 18.1705 10.0948 18.4666 9.24211C18.7628 8.38941 19.2419 7.61183 19.8704 6.9639C20.4989 6.31597 21.2615 5.81339 22.1048 5.4914C22.9481 5.16942 23.8516 5.03583 24.752 5.1C26.0752 5.09087 27.3978 5.15898 28.713 5.304V9.894H26.01C23.868 9.894 23.46 10.914 23.46 12.393V15.674H28.56L27.897 20.774H23.46V34H32.13C32.3756 34 32.6187 33.9516 32.8456 33.8577C33.0725 33.7637 33.2786 33.6259 33.4523 33.4523C33.6259 33.2786 33.7637 33.0725 33.8577 32.8456C33.9516 32.6187 34 32.3756 34 32.13V1.87C34 1.62443 33.9516 1.38126 33.8577 1.15438C33.7637 0.927503 33.6259 0.721356 33.4523 0.547711C33.2786 0.374065 33.0725 0.236322 32.8456 0.142345C32.6187 0.0483691 32.3756 0 32.13 0Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

// Ícono de Instagram
export const SVGInstagram = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77356 0.669434C5.88925 0.669433 4.08208 1.41778 2.74942 2.74993C1.41676 4.08208 0.667716 5.88897 0.666992 7.77327V26.7241C0.666992 28.6089 1.41572 30.4165 2.74846 31.7492C4.0812 33.082 5.88878 33.8307 7.77356 33.8307H26.7244C28.6087 33.83 30.4156 33.0809 31.7477 31.7483C33.0799 30.4156 33.8282 28.6084 33.8282 26.7241V7.77327C33.8275 5.88944 33.0788 4.08297 31.7468 2.7509C30.4147 1.41883 28.6082 0.670157 26.7244 0.669434H7.77356ZM28.7685 7.78419C28.7685 8.32704 28.5529 8.84766 28.169 9.23151C27.7851 9.61536 27.2645 9.83101 26.7217 9.83101C26.1788 9.83101 25.6582 9.61536 25.2744 9.23151C24.8905 8.84766 24.6749 8.32704 24.6749 7.78419C24.6749 7.24134 24.8905 6.72072 25.2744 6.33687C25.6582 5.95301 26.1788 5.73737 26.7217 5.73737C27.2645 5.73737 27.7851 5.95301 28.169 6.33687C28.5529 6.72072 28.7685 7.24134 28.7685 7.78419ZM17.2517 11.5722C15.7462 11.5722 14.3024 12.1702 13.2378 13.2348C12.1733 14.2993 11.5752 15.7432 11.5752 17.2487C11.5752 18.7542 12.1733 20.198 13.2378 21.2626C14.3024 22.3272 15.7462 22.9252 17.2517 22.9252C18.7572 22.9252 20.2011 22.3272 21.2656 21.2626C22.3302 20.198 22.9282 18.7542 22.9282 17.2487C22.9282 15.7432 22.3302 14.2993 21.2656 13.2348C20.2011 12.1702 18.7572 11.5722 17.2517 11.5722ZM8.84337 17.2487C8.84337 15.0194 9.72896 12.8814 11.3053 11.305C12.8817 9.72867 15.0197 8.84308 17.249 8.84308C19.4783 8.84308 21.6163 9.72867 23.1927 11.305C24.769 12.8814 25.6546 15.0194 25.6546 17.2487C25.6546 19.478 24.769 21.616 23.1927 23.1924C21.6163 24.7687 19.4783 25.6543 17.249 25.6543C15.0197 25.6543 12.8817 24.7687 11.3053 23.1924C9.72896 21.616 8.84337 19.478 8.84337 17.2487Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

export default { SVGLogoPionero, SVGLinkedIn, SVGFacebook, SVGInstagram };