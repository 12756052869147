import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../../components/atoms/Button/Button"
import "./EntradasBlog.scss"
import ObtenerUri from "../../utils/ObtenerUri"

const EntradasBlog = () => {
  // Consulta GraphQL para obtener las entradas del blog
  const data = useStaticQuery(graphql`
    query {
      pionero {
        posts {
          nodes {
            entrada {
              contenido
              imagenPortada {
                altText
                title
                srcSet
                sourceUrl
                sourceUrlSharp {
                  childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
                  }
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            title
            uri
          }
        }
      }
    }
  `)

  const posts = data.pionero?.posts?.nodes || []

  // Obtener todas las categorías únicas
  const allCategories = [
    "Todo",
    ...new Set(posts.flatMap(post => post.categories.nodes.map(cat => cat.name))),
  ]

  const [selectedCategories, setSelectedCategories] = useState(["Todo"])
  const [searchTerm, setSearchTerm] = useState("") // Estado para el término de búsqueda
  const [visiblePosts, setVisiblePosts] = useState(7) // Estado para controlar cuántos artículos se muestran
  const postsPerPage = 7 // Número de artículos a mostrar por clic

  // Manejar el botón "Ver más artículos"
  const handleVerMasArticulos = () => {
    setVisiblePosts(prev => prev + postsPerPage)
  }

  // Manejar el filtro de categorías
  const handleCategoryClick = category => {
    if (category === "Todo") {
      setSelectedCategories(["Todo"])
    } else {
      setSelectedCategories(prev => {
        const updatedCategories = prev.includes(category)
          ? prev.filter(cat => cat !== category) // Deseleccionar categoría
          : [...prev.filter(cat => cat !== "Todo"), category] // Seleccionar categoría

        // Si no hay categorías seleccionadas, volver a "Todo"
        return updatedCategories.length === 0 ? ["Todo"] : updatedCategories
      })
    }

    // Reiniciar el número de artículos visibles al cambiar de categoría
    setVisiblePosts(postsPerPage)
  }

  // Filtrar los posts según las categorías seleccionadas (condicional Y)
  const filteredByCategories =
    selectedCategories.includes("Todo")
      ? posts
      : posts.filter(post =>
        selectedCategories.every(selectedCategory =>
          post.categories.nodes.some(cat => cat.name === selectedCategory)
        )
      )

  // Filtrar los posts según el término de búsqueda
  const filteredPosts = filteredByCategories.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="entradas-blog">
      <div className="entradas-blog-header">
        {/* Botones de categorías */}
        <div className="entradas-blog-categorias">
          {allCategories.map((category, index) => (
            <button
              key={index}
              className={`entradas-blog-categoria-boton ${selectedCategories.includes(category) ? "active" : ""
                }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="entradas-blog-buscar">
          <input
            type="text"
            className="entradas-blog-buscar-input"
            placeholder="Buscar artículo"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />

          <svg className="entradas-blog-buscar-icono" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.23145 17.2657C6.97451 17.2657 5.06157 16.4817 3.49262 14.9137C1.92462 13.3467 1.14062 11.4342 1.14062 9.17633C1.14062 6.91844 1.92462 5.0055 3.49262 3.4375C5.06062 1.8695 6.97357 1.08597 9.23145 1.08691C11.4893 1.08786 13.4018 1.87186 14.9689 3.43891C16.5359 5.00597 17.3199 6.91844 17.3209 9.17633C17.3209 10.157 17.1472 11.1062 16.7999 12.0239C16.4526 12.9415 15.9962 13.7264 15.4305 14.3787L23.7726 22.718C23.9044 22.8497 23.975 23.0121 23.9844 23.205C23.9929 23.3961 23.9223 23.5669 23.7726 23.7175C23.622 23.8681 23.4555 23.9434 23.2729 23.9434C23.0903 23.9434 22.9237 23.8681 22.7731 23.7175L14.4324 15.3768C13.7265 15.9782 12.9147 16.4436 11.9971 16.773C11.0794 17.1024 10.1571 17.2672 9.23004 17.2672M9.23004 15.8554C11.103 15.8554 12.6846 15.2107 13.975 13.9213C15.2644 12.6319 15.9091 11.0502 15.9091 9.17633C15.9091 7.30244 15.2649 5.72127 13.9764 4.4328C12.6879 3.14433 11.1067 2.49962 9.23286 2.49868C7.35898 2.49868 5.77733 3.14339 4.48792 4.4328C3.19851 5.72221 2.55333 7.30339 2.55239 9.17633C2.55145 11.0493 3.19615 12.6304 4.48651 13.9199C5.77686 15.2093 7.35804 15.854 9.23004 15.854" fill="#2D2D2D" />
          </svg>
        </div>
      </div>
      {/* Grid de tarjetas */}
      <div className="entradas-blog-grid">
        {filteredPosts.length > 0 ? (
          filteredPosts.slice(0, visiblePosts).map((post, index) => {
            const { entrada, categories, title, uri } = post
            const image = getImage(entrada?.imagenPortada?.sourceUrlSharp)

            return (
              <div key={index} className="entradas-blog-card">
                <div className="entradas-blog-card-contenido">
                  {/* Categorías del card */}
                  <div className="entradas-blog-card-categorias">
                    {categories.nodes.map((cat, i) => (
                      <span key={i} className="entradas-blog-card-categoria">
                        {cat.name}
                      </span>
                    ))}
                  </div>

                  {/* Extracto */}
                  <h3
                    className="entradas-blog-card-titulo"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />

                  {/* Botón de leer artículo */}
                  <Button
                    url={ObtenerUri(uri)}
                    type="link"
                    size="small"
                    classEle="entradas-blog-card-boton"
                  >
                    Leer artículo
                  </Button>
                </div>
                {/* Imagen de portada */}
                {image && (
                  <GatsbyImage
                    image={image}
                    alt={entrada?.imagenPortada?.altText || "Imagen de portada"}
                    className="entradas-blog-card-imagen"
                  />
                )}
              </div>
            )
          })
        ) : (
          <p className="entradas-blog-no-entradas">No se encontraron entradas.</p>
        )}
      </div>
      {/* Botón "Ver más artículos" */}
      {visiblePosts < filteredPosts.length && (
        <div className="entradas-blog-ver-mas">
          <a
            onClick={handleVerMasArticulos}
            type="button"
            size="medium"
            class="entradas-blog-ver-mas-boton"
          >
            Ver más artículos
          </a>
        </div>
      )}
    </div>
  )
}

export default EntradasBlog