import React, { useState, useRef } from "react"
import { Grid } from "unsemantic/assets/react/dist/index"
import { navigate, Link } from "gatsby"
import Button from "../atoms/Button/Button"
import ObtenerUri from "../../utils/ObtenerUri" // Importar la función ObtenerUri


const getUrlParameter = (sParam) => {
  if (typeof window === "undefined") return null;

  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split("&");

  for (let i = 0;i < sURLVariables.length;i++) {
    const sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return null;
};


const FormEntradas = ({ proyectos }) => {
  const [nombre, setNombre] = useState("")
  const [apellidos, setApellidos] = useState("")
  const [telefono, setTelefono] = useState("")
  const [email, setEmail] = useState("")
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState("")
  const [validNombres, setValidNombres] = useState(false)
  const [validApellidos, setValidApellidos] = useState(false)
  const [validTelefono, setValidTelefono] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [validProyecto, setValidProyecto] = useState(false)
  const [aceptar, setAceptar] = useState(false)
  const [validAceptar, setValidAceptar] = useState(false)
  const [envio, setEnvio] = useState("") // Inicializar como cadena vacía

  // console.log("proyectos", proyectos)

  // console.log("proyectos", proyectos)

  const btnSubmitRef = useRef(null)

  let utm_source = getUrlParameter("utm_source") || "";
  let utm_medium = getUrlParameter("utm_medium") || "";
  let utm_campaign = getUrlParameter("utm_campaign") || "";
  let utm_term = getUrlParameter("utm_term") || "";
  let utm_content = getUrlParameter("utm_content") || "";

  const handleSubmit = async (event) => {
    event.preventDefault()

    let errores = 0

    if (nombre === "") {
      setValidNombres(true)
      errores++
    } else setValidNombres(false)

    if (apellidos === "") {
      setValidApellidos(true)
      errores++
    } else setValidApellidos(false)

    if (telefono === "") {
      setValidTelefono(true)
      errores++
    } else setValidTelefono(false)

    if (email === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setValidEmail(true)
      errores++
    } else setValidEmail(false)

    if (proyectoSeleccionado === "") {
      setValidProyecto(true)
      errores++
    } else setValidProyecto(false)

    if (!aceptar) {
      setValidAceptar(true)
      errores++
    } else setValidAceptar(false)

    if (errores === 0) {
      if (utm_campaign === undefined) utm_campaign = ""
      if (utm_content === undefined) utm_content = ""
      if (utm_medium === undefined) utm_medium = ""
      if (utm_source === undefined) utm_source = ""
      if (utm_term === undefined) utm_term = ""

      const proyecto = proyectos.find(
        (p) => p.proyecto.idProyectoCotiza === proyectoSeleccionado
      )

      console.log("proyectos seleccioandos", proyecto)

      console.log("proyectos seleccioandos", proyecto)

      const dataPost = {
        IdTipoPortal: 6,
        IdProyecto: proyecto.proyecto.idProyectoCotiza,
        // CodInmueble: 106341,
        Nombres: nombre,
        Apellidos: apellidos,
        Correo: email,
        Celular: telefono,
        IncluyeUtm: true,
        // numDormitorio: 1,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_term: utm_term,
        usuario: process.env.GATSBY_PAGINA_USER_EVOLTA,
        clave: process.env.GATSBY_PAGINA_PASS_EVOLTA,
      }

      const rpta = await fetch(
        `${process.env.GATSBY_PAGINA_URL_EVOLTA}/lead/save`,
        {
          method: "POST",
          body: JSON.stringify(dataPost),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      const data = await rpta.json()
      if (data === "000") {
        setEnvio(true)
        const urlGracias = ObtenerUri(proyecto.proyecto.urlGraciasBlog) // Usar ObtenerUri para la URL de gracias
        navigate(urlGracias)

      } else {
        setEnvio(false)
      }
    }
  }



  return (
    <div className="form-entrada">
      <h4 className="form-entrada-titulo font-roboto weight-300">
        Solicita información de nuestros proyectos
      </h4>
      <form onSubmit={handleSubmit} noValidate>
        {/* Selección de proyecto */}
        <Grid desktop="100" tablet="100" mobile="100">
          <div className="form-control">
            <label>
              <span className="form-control-texto">Proyecto*</span>
              <select
                value={proyectoSeleccionado}
                onChange={(e) => setProyectoSeleccionado(e.target.value)}
              >
                <option value="">Seleccionar proyecto</option>
                {proyectos
                  .filter((proyecto) => proyecto.proyecto.idProyectoCotiza) // Filtrar proyectos con idProyectoCotiza definido
                  .map((proyecto, index) => (
                    <option
                      key={index}
                      value={proyecto.proyecto.idProyectoCotiza}
                    >
                      {proyecto.title}
                    </option>
                  ))}
              </select>
              {validProyecto && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </label>
          </div>
        </Grid>

        {/* Nombres y Apellidos */}
        <div className="grid-form">
          <div className="form-control">
            <label>
              <span className="form-control-texto">Nombres*</span>
              <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                name="nombre"
                placeholder="Nombre"
              />
              {validNombres && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </label>
          </div>
          <div className="form-control">
            <label>
              <span className="form-control-texto">Apellidos*</span>
              <input
                type="text"
                value={apellidos}
                onChange={(e) => setApellidos(e.target.value)}
                name="apellidos"
                placeholder="Apellidos"
              />
              {validApellidos && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </label>
          </div>
        </div>

        <div className="grid-form">
          <div className="form-control">
            <label>
              <span className="form-control-texto">Teléfono*</span>
              <input
                type="tel"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                name="telefono"
                placeholder="Teléfono"
              />
              {validTelefono && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </label>
          </div>
          <div className="form-control">
            <label>
              <span className="form-control-texto">Correro*</span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                placeholder="Correo"
              />
              {validEmail && (
                <span className="size-17 color-rojo">
                  El campo es obligatorio.
                </span>
              )}
            </label>
          </div>
        </div>

        <div className="tyc">
          <label className="">
            <input
              type="checkbox"
              onChange={(e) => setAceptar(!aceptar)}
            />
            <span>
              He leído y acepto todos los{" "}
              <Link
                to="/terminos-y-condiciones/"
                className="color-negro weight-bold"
                target="_blank"
                rel="noreferrer"
              >
                términos y condiciones
              </Link>
              , y la{" "}
              <Link
                to="/politica-privacidad/"
                className="color-negro weight-bold"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidad
              </Link>{" "}
              del Grupo Pionero Inmobiliaria
            </span>
          </label>
          {validAceptar && (
            <span className="size-17 color-rojo">
              El campo es obligatorio.
            </span>
          )}
        </div>

        <Button
          size="small"
          refEle={btnSubmitRef}
          type="submit"
          classEle="form-entradas-button"
          disabled={envio}
        >
          Enviar
        </Button>
        {envio === false && envio !== "" && (
          <div className="font-roboto line-heihgt-normal">
            Error en el envío
          </div>
        )}
      </form>
    </div>
  )
}

export default FormEntradas