import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import React from "react"
import Layout from "../../components/Layout/Layout"
import BannerNosotros from "../../components/Nosotros/BannerNosotros"
import PioneroNosotros from "../../components/Nosotros/PioneroNosotros"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }

        nosotros {
          colorPagina
          tituloSiempre
          parrafoSiempre
          imagenVideoSiempre {
            title
            srcSet
            sourceUrl
            altText
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          linkVideoSiempre
          tituloPioneroInmobiliaria
          parrafoPioneroInmobiliaria
          imagenPioneroInmobiliaria {
            title
            srcSet
            sourceUrl
            altText
            sourceUrlSharp {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
              }
            }
          }
          tituloDirectorio
          equipoDirectorio {
            detalle
            cargo
            nombre
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
          }
          equipoGerencia {
            detalle
            cargo
            imagen {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: NONE
                  )
                }
              }
            }
            nombre
          }
        }
      }
    }
  }
`

export default function Nosotros({ data }) {
  const page = data.pionero.page

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty(
      "--color-pagina",
      page.nosotros.colorPagina
    )
  typeof window !== `undefined` &&
    document.documentElement.style.setProperty(
      "--color-texto",
      page.nosotros.colorPagina
    )

  // const handleTabGerencia = (e) => {
  //   const hasClass=  e.target.classList.contains("active")
  //   let tabsButton = document.querySelectorAll(`.tabs-button-Gerencia`);
  //   tabsButton.forEach( (ele) => ele.classList.contains("active") && ele.classList.remove("active"));
  //   let tabsPanel = document.querySelectorAll(`.tabs-panel-Gerencia`);
  //   tabsPanel.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"));
  //   let tabsPanel2 = document.querySelectorAll(`.tabs-panel2-Gerencia`);
  //   tabsPanel2.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"));
  //   let cards = document.querySelectorAll(`.directorionosotros-item-Gerencia`);
  //   cards.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"));
  //   if(!hasClass){
  //     e.target.classList.add("active")
  //     e.target.parentElement.classList.add("active")
  //     e.target.nextSibling.classList.add("active")
  //     document.getElementById(e.target.dataset.id).classList.add("active")
  //   }
  // }

  // const handleTabDirectorio = (e) => {
  //   console.log(e);
  //   const hasClass=  e.target.classList.contains("active")
  //   let tabsButton = document.querySelectorAll(`.tabs-button-Directorio`);
  //   tabsButton.forEach( (ele) => ele.classList.contains("active") && ele.classList.remove("active"))
  //   let tabsPanel = document.querySelectorAll(`.tabs-panel-Directorio`)
  //   tabsPanel.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"))
  //   let tabsPanel2 = document.querySelectorAll(`.tabs-panel2-Directorio`);
  //   tabsPanel2.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"));
  //   let cards = document.querySelectorAll(`.directorionosotros-item-Directorio`);
  //   cards.forEach((ele) => ele.classList.contains("active") && ele.classList.remove("active"));
  //   if(!hasClass){
  //     e.target.classList.add("active")
  //     e.target.parentElement.classList.add("active")
  //     e.target.nextSibling.classList.add("active")
  //     document.getElementById(e.target.dataset.id).classList.add("active")
  //   }
  // }

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page.title}</h1>
      <BannerNosotros
        titulo={page?.nosotros?.tituloSiempre}
        parrafo={page?.nosotros?.parrafoSiempre}
        imagen={page.nosotros.imagenVideoSiempre}
        urlVideo={page?.nosotros?.linkVideoSiempre}
      />
      <PioneroNosotros
        titulo={page?.nosotros?.tituloPioneroInmobiliaria}
        parrafo={page?.nosotros?.parrafoPioneroInmobiliaria}
        imagen={page?.nosotros?.imagenPioneroInmobiliaria}
      />
      {/* <DirectorioNosotros 
        titulo={page?.nosotros?.tituloDirectorio}
        directorio={page?.nosotros?.equipoDirectorio}
        isGerencia={false}
        index={1}
        // handleTab={handleTabDirectorio}
      /> */}

      {/* <DirectorioNosotros 
        titulo={page?.nosotros?.tituloGerencia}
        directorio={page?.nosotros?.equipoGerencia}
        isGerencia={true}
        index={2}
        handleTab={handleTabGerencia}
      /> */}
    </Layout>
  )
}
