import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import BannerSliderBlog from "../Banner/BannerSliderBlog"

const BANNER_BLOG_QUERY = graphql`
  {
    pionero {
      page(id: "5024", idType: DATABASE_ID) {
        blog {
          publicacionesBanner {
            titulo
            descripcion
            boton {
              title
              url
            }
            imagenDeFondo {
              altText
              title
              srcSet
              sourceUrl
              sourceUrlSharp {
                childImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }
`

const BannerBlog = ({ titlePage }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    pauseOnFocus: false,
    afterChange: current => {
      setActiveSlide(current)
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <StaticQuery
      query={BANNER_BLOG_QUERY}
      render={data => {
        let publicaciones = data.pionero?.page?.blog?.publicacionesBanner

        return (
          <>
            <section className="bg-negro">
              <h2 className="d-none">Banner Blog</h2>
              {publicaciones && settings && (
                <BannerSliderBlog
                  configuracion={settings}
                  publicaciones={publicaciones}
                  activeSlide={activeSlide}
                  totalSlide={publicaciones.length}
                  linkBanner={true}
                />
              )}
            </section>
          </>
        )
      }}
    />
  )
}

export default BannerBlog