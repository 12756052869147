import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../../components/atoms/Button/Button"
import ObtenerUri from "../../utils/ObtenerUri"
import "./BannerSliderBlog.scss"

const BannerSliderBlog = props => {
  const configuracion = props.configuracion
  const publicaciones = props.publicaciones
  return (
    <>
      {configuracion && publicaciones && (
        <Slider
          {...configuracion}
          className="banner-slider-blog bg-negro"
        >
          {publicaciones.map((publicacion, i) => {
            const { titulo, descripcion, boton, imagenDeFondo } = publicacion
            const image = getImage(imagenDeFondo?.sourceUrlSharp)

            return (
              <div key={i} className="banner-item-blog">
                {image && (
                  <GatsbyImage
                    image={image}
                    alt={imagenDeFondo?.altText || "Imagen del banner"}
                    className="banner-item-blog-image"
                  />
                )}
                <div className="banner-item-blog-content">
                  {titulo && (
                    <h3
                      className="banner-item-blog-title "
                      dangerouslySetInnerHTML={{ __html: titulo }}
                    />
                  )}
                  {descripcion && (
                    <div
                      className="banner-item-blog-description"
                      dangerouslySetInnerHTML={{ __html: descripcion }}
                    />
                  )}
                  {boton && boton.url && (
                    <Button
                      url={ObtenerUri(boton.url)}
                      type="link"
                      size="small"
                      classEle="banner-item-blog-button"
                    >
                      {boton.title || "Leer más"}
                    </Button>
                  )}
                </div>
              </div>
            )
          })}
        </Slider>
      )}
    </>
  )
}

export default BannerSliderBlog