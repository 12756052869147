export const transformMenuItems = menuItems => {
  // Crear un mapa de los elementos del menú
  const menuMap = {}
  const itemsCopy = menuItems.map(item => ({
    ...item,
    childItems: { edges: [] }, // Inicializa los elementos hijos
  }))

  // Llenar el mapa de menú con los elementos
  itemsCopy.forEach(item => {
    menuMap[item.databaseId] = item
  })

  // Asignar los elementos hijos a sus padres
  itemsCopy.forEach(item => {
    if (item.parentDatabaseId && menuMap[item.parentDatabaseId]) {
      menuMap[item.parentDatabaseId].childItems.edges.push({ node: item })
    }
  })

  // Filtrar los elementos que no tienen padres (son la raíz del menú)
  const transformedMenuItems = itemsCopy.filter(
    item => item.parentDatabaseId === 0
  )

  // Opcional: Eliminar propiedades innecesarias (si no se requieren más adelante)
  transformedMenuItems.forEach(item => {
    delete item.parentDatabaseId
    delete item.databaseId
    item.childItems.edges.forEach(edge => {
      delete edge.node.parentDatabaseId
      delete edge.node.databaseId
    })
  })

  return transformedMenuItems
}
