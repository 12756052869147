import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import BannerBlog from "../../components/Blog/BannerBlog"
import EntradasBlog from "../../components/Blog/EntradasBlog"
import SuscribeteBlog from "../../components/Blog/SuscribeteBlog"

export const query = graphql`
  query($id: ID!) {
    pionero {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        slug
        
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          } 
        } 
      }
    }
  }
`

export default function Blog({ data }) {
  const page = data.pionero.page;

  typeof window !== `undefined` &&
    document.documentElement.style.setProperty(
      "--color-texto",
      "#e8d5cc"
    )

  return (
    <Layout>
      <Seo post={page} />
      <h1 className="none">{page.title}</h1>
      <BannerBlog titlePage={page.title} />
      <EntradasBlog />
      <SuscribeteBlog />
    </Layout>
  )
}